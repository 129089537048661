<script lang="ts" setup>
import { PostProps } from "~/components/Card/Blog/Post.props"
import { MagazineCategoryProps } from "~/components/Card/MagazineCategory.props"

const { t, locale } = useI18n()

const heroData = computed(() => ({
  title: t("magazine.landing.title"),
  subtitle: t("magazine.landing.subtitle"),
  bgImage: getImageData({
    altText: t("magazine.landing.subtitle"),
    url: "/i5oh5hzamkwo/5GJBkllqz6zJjxT6vBIUmS/0f5c5bce7d463ddbec5988584390f134/cover-banner-wiki-magazine.svg",
    width: 1024,
    height: 350,
    preload: true,
    imgAttrs: { fetchpriority: "high" }
  })
}))

const { data, error } = await usePage(
  `/api/gluon/fetchTopMagazineHomePage/${locale.value}/top_magazine_home.js`,
  {
    method: "GET"
  }
)

let posts: PostProps[]
let categoriesMagazine: MagazineCategoryProps[] = data.value?.categories ?? []

const { data: getLast3Posts, error: errorGetLast3Posts } = await useFetch(
  `/api/magazine/getPostsPagination/0/3`,
  {
    method: "GET",
    transform: (data: { total: number; posts: Array<any> }) => {
      return {
        ...data,
        posts: data.posts.map((p) => ({
          ...p,
          image: {
            ...p.image,
            preload: true,
            imgAttrs: { fetchpriority: "high" }
          }
        }))
      }
    }
  }
)

if (errorGetLast3Posts.value) {
  console.warn("error get last 3 posts", errorGetLast3Posts.value)
} else {
  // @ts-ignore
  posts = getLast3Posts.value.posts
}

/*
const { data: getCategoriesOfMagazine, error: errorGetCategoriesOfMagazine } =
  await useFetch(`/api/magazine/getCategories`, {
    method: "GET"
  })

if (errorGetCategoriesOfMagazine.value) {
  console.warn(
    "error get categories of Magazine",
    errorGetCategoriesOfMagazine.value
  )
} else {
  categoriesMagazine = getCategoriesOfMagazine.value
}
*/
</script>

<template>
  <div class="magazine-landing" v-if="posts && posts.length > 0">
    <SeoBase v-bind="data!.seo" />
    <PagesMagazine
      :hero-section="heroData"
      :cards="posts"
      :categories="categoriesMagazine"
    />
  </div>
</template>
